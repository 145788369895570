import {graphql, navigate} from 'gatsby'
import React, {useEffect} from 'react'

const IndexPage = () => {
  useEffect(() => {
    navigate('/cn/zh/home')
  }, [])

  return <div />
}

IndexPage.defaultProps = {}

export default IndexPage

export const pageQuery = graphql`
  query templateAboutUs(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulAboutUs(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      homepageTitle
      imageOrVideo {
        gatsbyImageData(placeholder: BLURRED)
        file {
          url
          contentType
        }
        title
        description
      }
      metaTitle
      pageTitle
      name
      shortDescription {
        shortDescription
      }
      callToAction
      callToActionLinkedPage {
        slug
      }
      kpi1Number
      kpi1ShortDescription
      kpi1Icon {
        file {
          url
          contentType
        }
        title
        description
      }
      kpi2Number
      kpi2ShortDescription
      kpi2Icon {
        file {
          url
        }
        title
        description
      }
      kpi3Number
      kpi3ShortDescription
      kpi3Icon {
        file {
          url
        }
        title
        description
      }
      kpi4Number
      kpi4ShortDescription
      kpi4Icon {
        file {
          url
        }
        title
        description
      }
      metaDescription {
        metaDescription
      }
      pillarsItem1
      pillarsItem2
      pillarsItem3
      pillarsItem4
      pillarsItem5
      pillarsTitle
      surtitle
      contentSections1 {
        ...contentSectionFields
      }
      contentSections2 {
        ...contentSectionFields
      }
      contentSections3 {
        ...contentSectionFields
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
